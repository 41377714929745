export const CLASS_CYCLE = "bicycle";
export const CLASS_PEDESTRIANS = "person";
export const CLASS_VEHICLE = "vehicle";
export const CLASS_EVENT = "event";
export const CLASS_TAXIES = "taxis";
export const CLASS_WATTAXIES = "wat_taxi";
export const CLASS_INCIDENTS = "incident";
export const CLASS_PASSENGERS = "passenger";
export const CLASS_EMBARK_PASSENGERS = "embark";
export const CLASS_NONTAXIES = "nontaxis";
export const CLASS_ALL_TAXIS = "taxi";
export const CLASS_NORMAL_TAXIS = "normal_taxi";
export const CLASS_CAR = "car";
export const CLASS_SCOOTER = "scooter";
export const CLASS_MICROFREIGHT = "microfreight";
