import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { IoMdBicycle } from "react-icons/io";
import { RiWalkFill } from "react-icons/ri";
import { FaBox, FaCarAlt } from "react-icons/fa";
import { executeGetDetectionsRequest } from "api/common/detections.api";
import { CLASS_CYCLE, CLASS_MICROFREIGHT } from "constants/class.constants";
import { useSelector } from "react-redux";
import { CLASS_TYPE_PERSON, CLASS_TYPE_VEHICLE } from "constants/classType.constants";
import { useParams } from "react-router-dom";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import { Location } from "reducers/newLocation.reducer";
import StatsCard from "components/cards/StatsCard";
import { LocationType } from "enums/location.enums";
import { MdDeliveryDining } from "react-icons/md";
import { Box, styled } from "@mui/material";

/* eslint-disable no-empty */
interface StatisticsCardsProps {
    /* eslint-disable react/no-unused-prop-types */
    cycleCount?: number;
    pedestriansCount?: number;
    vehicleCount?: number;
    group?: Location;
}

const StyledBoxIcon = styled(FaBox)({
    position: "absolute",
    top: "6px",
    left: "10px",
    fontSize: "9px",
});

const StyledBox = styled(Box)({
    position: "relative",
    width: "43px",
    height: "43px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
});

const defaultStatCrdStyles = {
    gap: "0% !important",
    gridGap: "0% !important",
    boxSizing: "border-box",
    width: "100% !important",
    height: "100% !important",
    "& .count": {
        fontSize: "calc(1rem + (1vw))",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
};

export interface IDetections {
    locationId?: string;
    fromTime?: string;
    toTime?: string;
    classification?: string | string[];
    level?: number;
    field1?: string;
    includeChildLocations?: boolean;
}

const StatisticsCards: React.FC<StatisticsCardsProps> = ({ group }) => {
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [cyclesCount, setCyclesCount] = useState<number>(0);
    const [pedestriansCount, setPedestriansCount] = useState<number>(0);
    const [vehiclesCount, setVehiclesCount] = useState<number>(0);
    const [microfreightCount, setMicrofreightCount] = useState<number>(0);
    const [isCyclesCountLoading, setIsCyclesCountLoading] = useState<boolean>(false);
    const [isPedestriansCountLoading, setIsPedestriansCountLoading] = useState<boolean>(false);
    const [isVehiclesCountLoading, setIsVehiclesCountLoading] = useState<boolean>(false);
    const [isMicrofreightCountLoading, setIsMicrofreightCountLoading] = useState<boolean>(false);
    const isAllLocations = () => locationId === undefined;
    const cyclesAbortControllerRef = useRef<AbortController | null>(null);
    const pedestriansAbortControllerRef = useRef<AbortController | null>(null);
    const vehiclesAbortControllerRef = useRef<AbortController | null>(null);
    const microfreightAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        let level = 0;
        if (isAllLocations()) {
            level = -1;
        } else if (locationId !== undefined) {
            level = 3;
        } else if (group?.type === LocationType.REGION) {
            level = 1;
        } else if (group?.type === LocationType.ZONE) {
            level = 2;
        }

        await Promise.all([
            getCyclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_CYCLE,
                level,
            }),
            getPedestriansDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_TYPE_PERSON,
                level,
            }),
            getVehiclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_TYPE_VEHICLE,
                level,
            }),
            getMicrofreightDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_MICROFREIGHT,
                level,
            }),
        ]);
    };
    async function getCyclesDetections(data?: IDetections): Promise<void> {
        try {
            cyclesAbortControllerRef.current = new AbortController();
            const { signal } = cyclesAbortControllerRef.current;
            setIsCyclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setCyclesCount(first?.count || 0);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
        } finally {
            setIsCyclesCountLoading(false);
        }
    }

    async function getPedestriansDetections(data?: IDetections): Promise<void> {
        try {
            pedestriansAbortControllerRef.current = new AbortController();
            const { signal } = pedestriansAbortControllerRef.current;
            setIsPedestriansCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setPedestriansCount(first?.count || 0);
            }
        } catch (error) {
        } finally {
            setIsPedestriansCountLoading(false);
        }
    }

    async function getVehiclesDetections(data?: IDetections): Promise<void> {
        try {
            vehiclesAbortControllerRef.current = new AbortController();
            const { signal } = vehiclesAbortControllerRef.current;
            setIsVehiclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setVehiclesCount(first?.count || 0);
            }
        } catch (error) {
        } finally {
            setIsVehiclesCountLoading(false);
        }
    }

    async function getMicrofreightDetections(data?: IDetections): Promise<void> {
        try {
            microfreightAbortControllerRef.current = new AbortController();
            const { signal } = microfreightAbortControllerRef.current;
            setIsMicrofreightCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setMicrofreightCount(first?.count || 0);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsMicrofreightCountLoading(false);
        }
    }

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);

        return () => {
            cyclesAbortControllerRef.current?.abort();
            pedestriansAbortControllerRef.current?.abort();
            vehiclesAbortControllerRef.current?.abort();
        };
    }, [locationId, startTime, endTime]);

    return (
        <Grid container spacing={2} sx={{gridGap: "0 !important", gap: "0 !important"}} className="stats-container">
            <Grid key="cycles" item sm={12} md={6} lg={4} xl={3}>
                <StatsCard
                    isLoading={isCyclesCountLoading}
                    title="Total number of cycles detected"
                    count={cyclesCount}
                    icon={<IoMdBicycle />}
                    additionalProperties={{
                        sx: defaultStatCrdStyles,
                    }}
                />
            </Grid>
            <Grid key="pedestrians" item sm={12} md={6} lg={4} xl={3}>
                <StatsCard
                    isLoading={isPedestriansCountLoading}
                    title="Total number of pedestrians detected"
                    count={pedestriansCount}
                    icon={<RiWalkFill />}
                    additionalProperties={{
                        sx: defaultStatCrdStyles,
                    }}
                />
            </Grid>
            <Grid key="vehicles" item sm={12} md={6} lg={4} xl={3}>
                <StatsCard
                    isLoading={isVehiclesCountLoading}
                    title="Total number of vehicles detected "
                    count={vehiclesCount}
                    icon={<FaCarAlt />}
                    additionalProperties={{
                        sx: defaultStatCrdStyles,
                    }}
                />
            </Grid>
            <Grid key="Microfreight" item sm={12} md={6} lg={4} xl={3}>
                <StatsCard
                    isLoading={isMicrofreightCountLoading}
                    title="Total number of Microfreight detected"
                    count={microfreightCount}
                    icon={
                        <StyledBox>
                            {/* The Scooter */}
                            <MdDeliveryDining style={{ fontSize: "25px" }} />
                            {/* The Delivery Box */}
                            <StyledBoxIcon />
                        </StyledBox>
                    }
                    additionalProperties={{
                        sx: defaultStatCrdStyles,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default StatisticsCards;
