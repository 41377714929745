import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import StatisticsCards from "features/app-cycle-ways/statistics/StatisticsCards";
import PaperWrapper from "components/wrappers/PaperWrapper";
import CommonTable from "components/tables/CommonTable";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import Map from "features/app-cycle-ways/map/Map";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { executeGetDetectionsByLocationsRequest } from "api/common/detections.api";
import TableHeading from "components/headings/TableHeading";
import { CLASS_EVENT, CLASS_PEDESTRIANS, CLASS_CYCLE, CLASS_VEHICLE } from "constants/class.constants";
import { buildLocationAddressStr } from "utils/build-location-str";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppTitlePrefix } from "constants/app.constants";
import IotVisionSelectFiled from "components/common/IotVisionSelectField";
import { LocationDetection } from "types/location-group/location-detection";
import { Location } from "reducers/newLocation.reducer";
import { useNavigate } from "react-router";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
// import { getChildrenCounts } from "utils/get-children-count-in-tree";
import { navigateTo404Page } from "utils/navigation";
import NswLogo from "../../../assets/images/nsw-logo.png";
import GroupSummeryHeader from "./GroupSummaryCard";

export interface DetectionsData {
    groupId?: string;
    fromTime: string;
    toTime: string;
    classification: string | unknown;
    locationId?: string;
}

export default function CycleWaysDashBoard() {
    const { appCode } = useSelector((state) => state.auth);
    const { locations } = useSelector((state) => state.newLocation);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const columns: GridColumns = [
        {
            field: "locationName",
            headerName: "Location",
            flex: 2,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.locationName} arrow>
                    <span> {params.row.locationName}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "groupName",
            headerName: "LGA",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.groupName || ""} arrow>
                    <span> {params.row.groupName}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "location",
            headerName: "",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`/${appCode}/home/location/${params.row.id}`}>{params.value}</Link>
            ),
            align: "right",
        },
    ];

    const pageSize = 5;

    const rowsPerPageOptions = [5, 10];
    const hideFooterPagination = true;

    const options = [
        {
            value: CLASS_CYCLE,
            title: "Highest Cyclists",
        },
        {
            value: CLASS_PEDESTRIANS,
            title: "Highest Pedestrians",
        },
        {
            value: CLASS_VEHICLE,
            title: "Highest Vehicles",
        },
        // {
        //     value: CLASS_EVENT,
        //     title: "Highest Events",
        // },
    ];
    const [gridData, setGridData] = useState<LocationDetection[]>([]);
    const [filterClass, setFilterClass] = useState<string | undefined>(CLASS_CYCLE);
    const [selectedGroup, setSelectedGroup] = useState<Location>();
    const [selected, setSelected] = useState<Location>();
    // const [childrenCount, setChildrenCount] = useState<number>(0);
    const [isGroup, setIsGroup] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        if (locations.length > 0) {
            const selectedLocation: Location | undefined = locations.find((loc) => loc.id === locationId);
            const parentId: string | undefined = selectedLocation?.parentId;
            if (parentId) {
                await getDetectionsByLocations({
                    groupId: parentId,
                    fromTime: startTime,
                    toTime: endTime,
                    classification: filterClass,
                    locationId,
                });
            } else {
                await getDetectionsByLocations({
                    fromTime: startTime,
                    toTime: endTime,
                    classification: filterClass === CLASS_EVENT ? undefined : filterClass,
                });
            }
        }
    };
    async function getDetectionsByLocations(data?: DetectionsData): Promise<void> {
        try {
            locationsAbortControllerRef.current = new AbortController();
            const { signal } = locationsAbortControllerRef.current;
            const response = await executeGetDetectionsByLocationsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const sortedLocationByDetection: LocationDetection[] = [];
                response
                    .sort((a, b) => parseInt(b.count) - parseInt(a.count))
                    .slice(0, 5)
                    .forEach((detection) => {
                        const location: Location | undefined = locations.find(
                            (location) => location.id === detection.locationId,
                        );
                        const group: Location | undefined = locations.find(
                            (loc: Location) => loc.id === location?.parentId,
                        );

                        if (location) {
                            sortedLocationByDetection.push({
                                ...location,
                                locationName: location?.name,
                                address: buildLocationAddressStr(location),
                                location: "View Location",
                                groupName: group?.name,
                            });
                        }
                    });
                setGridData(sortedLocationByDetection);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleChange = (v: SelectChangeEvent<string>): void => {
        setFilterClass(v.target.value);
    };

    // const handleChildrenData = (location: Location) => {
    //     const childrenDetails = getChildrenCounts([location]);
    //     setChildrenCount(childrenDetails[location.id]);
    // };

    useEffect(() => {
        let selected!: Location | undefined;
        if (locationId) {
            selected = locations.find((loc: Location) => loc.id === locationId);

            if (selected?.type === "region") {
                setIsGroup(true);
                setTitle("Region Summary");
                // handleChildrenData(selected);
            }
            if (selected?.type === "zone") {
                setIsGroup(true);
                setTitle("Zone Summary");
                // handleChildrenData(selected);
            }
            if (selected) {
                setSelectedGroup(selected);
            } else {
                navigateTo404Page(navigate, appCode);
            }
            setSelected(selected);
        } else {
            setIsGroup(false);
            setSelected(undefined);
            setTitle("Summary of All Locations");
        }
    }, [locationId]);

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            locationsAbortControllerRef.current?.abort();
        };
    }, [locationId, startTime, endTime, filterClass]);

    return (
        <>
            <Helmet>
                <title>
                    {AppTitlePrefix} | {title}
                </title>
                <link rel="icon" id="cw-dashboard" type="image/png" href={NswLogo} sizes="32x32" />
            </Helmet>
            <Grid container spacing={1}>
                {isGroup && (
                    <Grid item xs={12}>
                        <GroupSummeryHeader
                            // childrenCount={childrenCount}
                            // devicesCount={0}
                            name={selectedGroup?.name}
                            type={selectedGroup?.type}
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                    <SectionHeading heading={title} icon={<AiFillAppstore className="icon" />} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                        <HeaderDatePicker />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <StatisticsCards group={selectedGroup} />
                </Grid>
                <Grid item xs={12}>
                    <PaperWrapper>
                        <Map id={selected?.id} />
                    </PaperWrapper>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} sm={12}>
                        <PaperWrapper>
                            <Grid container spacing={1}>
                                <Grid item xs={9} sm={9} md={8} lg={9} xl={10}>
                                    <TableHeading heading="Top Locations" />
                                </Grid>
                                <Grid item xs={3} sm={3} md={4} lg={3} xl={2}>
                                    <IotVisionSelectFiled fullWidth value={filterClass} onChange={handleChange}>
                                        {options?.map((option) => {
                                            return (
                                                <MenuItem key={option?.value} value={option?.value}>
                                                    {option?.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </IotVisionSelectFiled>
                                    {/* </div> */}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <CommonTable
                                        columns={columns}
                                        rows={gridData}
                                        pageSize={pageSize}
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        hideFooterPagination={hideFooterPagination}
                                    />
                                </Grid>
                            </Grid>
                        </PaperWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
