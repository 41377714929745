import React from "react";
import Grid from "@mui/material/Grid";
import { FaMapMarkerAlt } from "react-icons/fa";
import { LocationType } from "enums/location.enums";
import { humanize } from "utils/common";

interface GroupSummaryCardProps {
    // childrenCount?: number;
    name?: string;
    type?: string;
}

const GroupSummaryCard: React.FC<GroupSummaryCardProps> = ({
    // childrenCount,
    name,
    type }) => {
    return (
        <Grid container className="region-stat-card-wrapper m-0">
            <Grid container item xs={11} sm={11} md={10} lg={11} className="region-statlocation-card-Innerwrapper">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <div className="region-statlocation-row">
                        <div className="LocationIcon-wrapper">
                            <FaMapMarkerAlt />
                        </div>
                        <span className="region-statlocation-header">
                            {type === LocationType.REGION
                                ? humanize(LocationType.REGION)
                                    : humanize(LocationType.ZONE)} - {name}
                        </span>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} className="region-stat-innaer-wrapper_right">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="mobilemargin">
                        <Grid item xs={12} sm={12} md={12} lg={12} className="region-state-row-left">
                            {/* <FaMapMarkerAlt />
                            Total number of locations: &nbsp;
                            <span className="fw400">{childrenCount}</span> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GroupSummaryCard;
