import React from "react";
import Grid from "@mui/material/Grid";
import { FaMapMarkerAlt } from "react-icons/fa";
// import { GiCctvCamera } from "react-icons/gi";

interface GroupSummaryCardProps {
    // childrenCount?: number;
    // devicesCount?: number;
    name?: string;
    type?: string;
}

const GroupSummaryCard: React.FC<GroupSummaryCardProps> = ({
    // childrenCount,
    // devicesCount,
    name,
    type }) => {
    return (
        <Grid container className="region-stat-card-wrapper m-0">
            <Grid container item xs={11} sm={11} md={10} lg={11} className="region-statlocation-card-Innerwrapper">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <div className="region-statlocation-row">
                        <div className="LocationIcon-wrapper">
                            <FaMapMarkerAlt />
                        </div>
                        <span className="region-statlocation-header">
                            {type === "region" ? "Region" : "Zone"} - {name}
                        </span>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} className="region-stat-innaer-wrapper_right">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="mobilemargin">
                        <Grid item xs={12} sm={12} md={12} lg={12} className="region-state-row-left">
                            {/* <FaMapMarkerAlt />
                            Total number of locations: &nbsp;
                            <span className="fw400">{childrenCount}</span> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="region-state-row-left">
                            {/* <GiCctvCamera /> Total number of cameras: &nbsp;
                            <span className="fw400">{devicesCount}</span> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid
                item
                xs={1}
                sm={1}
                md={2}
                lg={1}
                className="right-content right-content-data-red"
            > */}
            {/* <Grid item xs={1} sm={1} md={2} lg={1} className="right-content right-content-data-yellow"> */}
            {/* <div className="txtWrapper">
                    <div className="lblOne">Traffic</div>
                    <div className="lblTwo">High</div>
                </div>
            </Grid> */}
        </Grid>
    );
};

export default GroupSummaryCard;
