import { executeGetDetectionsOvertimeRequest } from "api/common/detections.api";
import React, { useEffect, useState } from "react";
import { DetectionOvertimeResponse } from "types/detections/detection-overtime";
import { Classification } from "enums/classifications.enum";
import { filterDataByClassifications } from "utils/common";
import { MAX_HEX_COLOR_VALUE } from "constants/colours.constants";
import SynchronizedLineChart from "./SynchronizedLineChart";

type HistoricalTrendLineChartProps = {
    fromTime: string;
    toTime: string;
    locationId?: string;
    scaleUnit?: string;
    classification?: string;
    onOptionsUpdate: (options: string[]) => void;
    title: string;
    period?: string;
};
interface DetectionsRequest {
    fromTime: string;
    toTime: string;
    scaleUnit: string | undefined;
    classification?: string | undefined | null;
    period?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/function-component-definition
export default function HistoricalTrendLineChart({
    fromTime,
    toTime,
    locationId,
    scaleUnit,
    classification,
    onOptionsUpdate,
    title,
    period,
}: HistoricalTrendLineChartProps) {
    const [historicalTrendsData, setHistoricalTrendsData] = useState<DetectionOvertimeResponse[]>([]);

    useEffect(() => {
        // eslint-disable-next-line require-await
        (async (): Promise<void> => {
            if (locationId) {
                getHistoricalTrendsData(locationId, {
                    fromTime,
                    toTime,
                    scaleUnit,
                    ...(!classification || classification === "All" ? {} : { classification }),
                    ...(period ? { period } : {}),
                });
            }
        })();
    }, [fromTime, toTime, locationId, classification, scaleUnit, period]);

    const changeNullClassToUnknown = (dataArray: DetectionOvertimeResponse[]): DetectionOvertimeResponse[] => {
        return dataArray.map((data: DetectionOvertimeResponse) => {
            return {
                ...data,
                classification: data.classification ? data.classification : "unknown",
            };
        });
    };

    async function getHistoricalTrendsData(locationId: string, data?: DetectionsRequest): Promise<void> {
        try {
            const response: DetectionOvertimeResponse[] = await executeGetDetectionsOvertimeRequest(locationId, data);
            if (response) {
                const removableClassifications = [
                    Classification.ARRIVAL,
                    Classification.DEPARTURE,
                    Classification.UNATTENDED_BICYCLE,
                    Classification.VEHICLE,
                    Classification.PERSON,
                ];
                const acceptableDataset = filterDataByClassifications(
                    changeNullClassToUnknown(response),
                    removableClassifications,
                    true,
                );
                setHistoricalTrendsData(acceptableDataset);
                const uniques: string[] = Array.from(
                    new Set(
                        response.map(
                            (item: { classification: string; count: number; inTime: Date }) => item.classification,
                        ),
                    ),
                );
                uniques.push("All");
                onOptionsUpdate(uniques);
            }
        } catch (e) {
            console.log(e);
        } finally {
            // setLoading(false);
        }
    }

    function generateLineColors(noOfColors: number) {
        return Array.from(
            { length: noOfColors },
            () =>
                `#${Math.floor(Math.random() * MAX_HEX_COLOR_VALUE)
                    .toString(16)
                    .padStart(6, "0")}`,
        );
    }

    return (
        <SynchronizedLineChart
            title={title}
            data={historicalTrendsData}
            fromTime={fromTime}
            toTime={toTime}
            timeUnit={scaleUnit}
            lineColors={generateLineColors(historicalTrendsData.length)}
        />
    );
}
